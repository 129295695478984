// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Components/Header/Header";

import Tours from "./Routes/Tours/Tours";
import "./App.css";
import Home from "./Routes/Home/Home";
import Footer from "./Components/Footer/Footer";
import Wedding from "./Components/Wedding/Wedding";
import Carrental from "./Routes/Carrental/Wedding";
import AboutUs from "./Routes/AboutUs/AboutUs";
import { Snowfall } from "react-snowfall";

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Snowfall/> */}
        <Header />
        <Snowfall
          color="white"
          snowflakeCount={200} // Number of snowflakes
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            zIndex: 1,
          }}
        />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tours" element={<Tours />} />
          <Route path="/wedding-cars" element={<Wedding />} />
          <Route path="/car-rental" element={<Carrental />} />
          <Route path="/aboutus" element={<AboutUs />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
