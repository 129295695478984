// src/components/AboutUs.js
import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="about-us-wrapper">
      {/* Banner Section */}
      <section className="about-banner">
        <div className="banner-content">
          <h1>Welcome to VIP Travels</h1>
          <p>
            Redefining travel experiences with reliability, comfort, and luxury. Your perfect journey starts here!
          </p>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <div className="choose-us-grid">
          <div className="grid-item">
            <h3>Luxury Fleet</h3>
            <p>We offer a range of vehicles from economy to luxury, tailored to your travel needs.</p>
          </div>
          <div className="grid-item">
            <h3>Professional Drivers</h3>
            <p>Our experienced drivers ensure you reach your destination safely and on time.</p>
          </div>
          <div className="grid-item">
            <h3>Affordable Prices</h3>
            <p>Top-notch services at prices that won’t break the bank.</p>
          </div>
          <div className="grid-item">
            <h3>24/7 Support</h3>
            <p>Our customer service team is always here to assist you, anytime, anywhere.</p>
          </div>
        </div>
      </section>

      {/* Mission, Vision & Values Section */}
      <section className="mission-vision-values">
        <div className="mv-section">
          <h2>Our Mission</h2>
          <p>
            To deliver exceptional car rental services that exceed customer expectations, ensuring every journey is smooth and memorable.
          </p>
        </div>
        <div className="mv-section">
          <h2>Our Vision</h2>
          <p>
            To be a global leader in the car rental industry, synonymous with innovation and customer satisfaction.
          </p>
        </div>

      </section>

      {/* Meet Our Team Section */}
      
      {/* Call to Action Section */}
      <section className="cta">
        <h2>Ready to Start Your Journey?</h2>
        <p>Book your ride today and experience the Guid Lanka's difference!</p>
        <button className="cta-button">Contact Us</button>
      </section>
    </div>
  );
};

export default AboutUs;
