// src/components/WeddingCarBooking.js
import React from "react";
import "./WeddingCarBooking.css";
import weddingcarImage from "./culinary-delight-wedding-car-white-background.jpg"

const WeddingCarBooking = () => {
  return (
    <div className="booking-container">
      <div className="illustration">
        <img src={weddingcarImage} alt="Wedding illustration" />
      </div>
      
   
    </div>
  );
};

export default WeddingCarBooking;
